import React, { Fragment, useContext, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
// import LoggedData from "./loggedData";

/*
import dynamic from "next/dynamic";
const LoggedData = dynamic(() => import("./loggedData"));*/

import loadable from '@loadable/component'
import AppContext from "../../AppContext";
const LoggedData = loadable(() => import('./loggedData'))

const LoginProvider = () => {
  const contextData = useContext(AppContext);
  const { signInWithGoogle, login, logout } = useAuth();
  const [loggedUserData, setloggedUserData] = useState(null);
  const signInWithGoogleFunc = () => {
    signInWithGoogle();
  };

  useEffect(() => {
    contextData.settempList([]);
  }, []);
  

  if (useAuth().currentUser) {
    return (
      <Fragment>
        <LoggedData />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="text-center">
          <label className="text-2xl">Login</label>
        </div>
        <div className="p-2"></div>
        <div className="flex justify-center">
          {/* <div className=" bg-black font-bold text-white border-black border-4 px-4 py-2 rounded-lg content-center "> */}
            <button
              className="btn btn-info flex text-white font-bold normal-case"
              onClick={() => {
                signInWithGoogleFunc();
              }}
            >
              Login With Google
            </button>
          {/* </div> */}
        </div>
      </Fragment>
    );
  }
};

export default LoginProvider;
